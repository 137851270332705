.swal2-container {
  z-index: 20000 !important;
}
.i-btn:hover {
  background: transparent;
}


.plans-info{
  font-size: 11.3px;
}

.plans-info > h4 {
  margin-bottom: -10px;
}